export const light = {
  bg: {
    light: '#ffffff',
    light2: '#F9F8F7',
    light3: '#EFEFEF',
    green: '#16484A',
    darkGreen: '#0E3233',
    darkerGreen: '#071819',
    lightGreen: '#CCDD2E',
    dark: '#333333',
    dark2: '#071819',
  },
  font: {
    primary: '#757474',
    accent: '#CCDD2E',
    dark: '#333333',
    third: '#23797B',
    fifth: '#9E9E9E',
    error: '#FF0000',
    white: '#ffffff',
    black: '#000',
  },
  border: {
    borderBtn: '#23797B',
    borderBtnWhite: '#fff',
  },
};
